import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Whatsapp from '../../../../../components/Whatsapp';
import { logo, authTitle, urlWebsite } from '../../../../../config';
import { useConfiguration } from '../../../../../hooks/Configuration';
import {

  Capa,

} from './styles';

const SidebarTheme: React.FC = () => {
  const { configuration, projectHash } = useConfiguration();



  return (
    <>
      <Capa>
        <div className='p50 ' style={{ padding: '20px' }} >



          <div className='titleTheme'>

            <h2>{configuration?.current_event_id_response?.title}</h2>
            {configuration?.current_event_id_response?.description && <p dangerouslySetInnerHTML={{ __html: configuration?.current_event_id_response?.description }} />}
            {configuration?.current_event_id_response?.image && <img src={`${urlWebsite}/${configuration?.current_event_id_response?.image}`} style={{ alignSelf: 'center', margin: '10px 0px', maxHeight: '50vh' }} />}
          </div>
        </div>
        <div style={{ display: 'flex', background: '#fff', borderRadius: '15px', maxWidth: '300px', alignItems: 'center', flexDirection: 'column' }}>
          <div className="titleCapa">
            {configuration.data_image ? <img src={`${urlWebsite}/${configuration.data_image}`} style={{ width: '100%', opacity: 1, maxWidth: '500px', margin: '0px auto' }} /> : <></>}

            {configuration.certificates && configuration.certificates === 'yes' ? <Link to="/certificados" style={{ margin: '10px' }}><button className="defaultButton">Certificados</button></Link> : <></>}

            {/*
            <Link to={`/app/${projectHash}/inscricao`} style={{ margin: '5px 10px' }}><button className="defaultButtonReverse">Inscreva-se</button></Link>
           
  */}

            {configuration?.active_pages?.map(button => {
              return button.button === 'yes' ? button.type === 'external' ? <a href={button.url} target="_BLANK" style={{ margin: '5px 10px' }}><button className="defaultButton">{button.title}</button></a> : <Link to={`/app/${projectHash}/${button.url}`} style={{ margin: '5px 10px' }}><button className="defaultButton">{button.title}</button></Link> : <></>
            })}

            {configuration?.current_event_id_response?.status === 'Sim' ? <Link to={`/app/${projectHash}/login`} style={{ margin: '5px 10px' }}><button className="defaultButton">ÁREA DO PARTICIPANTE</button></Link> : <></>}

            {/* configuration?.allow_isention_recurse === 'yes' ? <Link to={`/app/${projectHash}/solicitar-isencao`} style={{ margin: '5px 10px' }}><button className="defaultButton">SOLICITAR ISENÇÃO DE PAGAMENTO DO VALOR DA INSCRIÇÃO</button></Link> : <></> */}


          </div>
        </div>
      </Capa>

    </>
  );
};
export default SidebarTheme;
